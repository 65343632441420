.Difficulty {
    align-self: flex-end;
    justify-self: flex-end;
    justify-content: flex-end;
    flex: 1;
    display: grid;
    grid-template-columns: min-content min-content;
    align-items: center;
}

.Label {
    font: var(--difficulty-label-font, normal 0.75rem/0.75rem var(--font-primary));
}

.Stars {
    width: 3.27rem;
}