.Theme {
  --font-monospace: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  --font-primary: "SuperMarioMakerExtended", sans-serif;
  --font-secondary: "SuperMarioMakerExtended", sans-serif;
  --font-tertiary: "SuperMario", sans-serif;
  --h1-font: bolder 1.4rem/3rem var(--font-secondary);
  --h2-font: bolder 1rem/1.2rem var(--font-primary);
  --gap: .5rem;

  --background: #facd00;
  --text-color: #fff;
  --text-mix-blend-mode: none;
  --link-color: #61dafb;
  --text-shadow: none;
  --letter-spacing: -0.03rem;
  --link-color-active: #2c001f;
  --box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  --button-color: white;
  --button-padding: 0.6em 1em;
  --button-font: normal 1em/1.2em var(--font-primary);
  --button-background: #d30561;
  --button-border: none;
  --button-border-radius: 0.25em;
  --button-after-background: none;
  --button-after-border: none;
  --button-after-box-shadow: none;

  --app-background: #01346d
    radial-gradient(
      circle at 50% 0%,
      rgba(49, 78, 137, 1) 0%,
      rgba(2, 53, 112, 1) 35%,
      rgba(0, 43, 92, 1) 50%
    );

  --tag-color: white;
  --tag-new-color: white;
  --tag-other-color: white;
  --tag-background: #050159;
  --tag-new-background: #21016d;
  --tag-other-background: #01346d;
  --app-font: normal 1rem/1.2rem var(--font-primary);
  --about-button-after-background: none;

  --modal-inner-color: var(--text-color);
  --modal-outer-background: rgba(0, 0, 0, 0.4);
  --modal-inner-background: var(--card-background);
  --modal-inner-box-shadow: 0px 0.25rem 0.25rem rgba(46, 47, 48, 0.1);

  --batch-number-background: #d30561;
  --batch-number-border: 1px solid rgba(0, 0, 0, 0.3);
  --batch-number-font: 400 2rem/1.125rem var(--font-tertiary);
  --batch-number-color: white;

  --navigation-button-after-background: #ef25d8;

  --card-background: #021f40;
  --card-border-radius: 0.3rem;

  --level-code-color: white;
  --level-code-background: #d30561;
  --level-code-font: 900 1.5rem/3rem var(--font-primary);

  --max-content-width: 617px;
  --app-min-height: 100svh;
  --app-min-width: 32rem;
  --card-width: 100%;

  --footer-font: 0.875rem/.875rem var(--font-primary);
  --footer-color: #fff;
  --footer-background: #333;
}
