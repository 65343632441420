.TagsAndDifficulty {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: var(--gap, .5rem);
}

.LevelCode {
  border-radius: 0px 0px 0.25rem 0.25rem;
  padding-left: 1rem;
  font: var(--level-code-font, 'inherit');
  background: var(--level-code-background, #111);
  mix-blend-mode: var(--level-code-mix-blend-mode, none);
  filter: var(--level-code-filter, none);
  color: var(--level-code-color, #111);
}
img.LevelImage {
  display: flex;
  max-width: 100%;
  object-fit: cover;
}
.Description > div {
  display:grid;
  gap: var(--gap, .5rem);
}
.Description > span {
  white-space: pre-wrap;
}
.Description > div > p {
  white-space: pre-wrap;
}