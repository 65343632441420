.BatchCard {
    --_batch-number-width: var(--batch-number-width, 2.4375rem);
    grid-template-columns: var(--_batch-number-width) 1fr min-content;
}

.BatchNumber {
    display: flex;
    border-radius: 50%;
    height: var(--_batch-number-width);
    width: var(--_batch-number-width);
    text-align: center;
    justify-content: center;
    align-items: center;
    font: var(--batch-number-font);
    background: var(--batch-number-background, white);
    border: var(--batch-number-border, none);
    color: var(--batch-number-color, black);
    mix-blend-mode: var(--batch-number-mix-blend-mode, none);
}


.BatchInfo {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 0 0;
}

.BatchReleaseDay {
    font-size: 1rem;
    line-height: 1.125rem;
    font-family: var(--font-secondary);
}

.BatchLevelAmount {
    font-size: .875rem;
    line-height: 1rem;
}