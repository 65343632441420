.Card {
    display: grid;
    box-shadow: var(--box-shadow);
    position: relative;
    padding: var(--card-padding, 1em);
    border-radius: var(--card-border-radius);
    background: var(--card-background);
    gap: var(--card-gap, .5em);
    height: max-content;
    text-decoration: none;
    font-weight: var(--font-weight);
}
.CardOuter h1:empty, .Card h2:empty, .Card p:empty {
  display: none;
}

.CardOuter {
    display: grid;
}

.CardDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.IsClickableCard {
    cursor: pointer;
    text-decoration: none;
}

.IsClickableCard:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.HasCardIllustration {
    position: relative;
}

.HasSpecialCardIllustration {
    padding-right: 13em;
}

.SpecialCardIllustration {
    position: absolute;
        right: 5px;
        bottom: 5px;
}

.CardIllustration picture {
    display: flex;
}

.CardIllustration {
    position: absolute;
    bottom: -6px;
    right: 0;
    transform: var(--card-illustration-transform, rotate(4deg) translate(2em, 2rem));
    box-shadow: var(--illustration-box-shadow, var(--box-shadow));
    max-width: 220px;
    margin-left: 180px;
}

@media only screen and (max-width: 560px) {

    .HasSpecialCardIllustration {
        padding-right: 0;
    }
    .SpecialCardIllustration,
    .CardIllustration {
        display: none;
    }
}