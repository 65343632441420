.CreditsCard {
  text-align: left;
  padding-top: 1em;
  padding-bottom: 1em;
}

.CreditsCard h2 {
  font-size: 1rem;
  line-height: 1.125rem;
  padding-bottom: 1rem;
}

.CreditsCard dl {
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  display: grid;
  font-size: 1rem;
  line-height: 1.125rem;
  padding-bottom: 2rem;
}

.CreditsCard dl dt {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
  padding-right: 0.3rem;
}

.CreditsCard dl dt+dd {
  grid-column-start: inherit;
  padding-bottom: 0rem;
}

.CreditsCard dl dd {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 1rem;
  line-height: 1.125rem;
  grid-column-start: 2;
  padding-bottom: 0.3rem;
}